import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import ProtectedPage from "./ProtectedPage";
import Register from "./tushar/LoginPage";
import Dashboard from "./pages/Dashboard";
import { Navbar } from "./pages/Navbar";
import SemResults from "./pages/semResults/SemResults";
import LoginPage from "./tushar/LoginPage";
import DetailedSem from "./pages/semResults/semComponets/DetailedSem";
import DetailedSubject from "./pages/semResults/semComponets/DetailedSubject";

function App() {
  const [token, setToken] = useState("");

  const handleLogin = (newToken) => {
    setToken(newToken);
  };

  const handleLogout = () => {
    setToken("");
    window.localStorage.removeItem("token");
  };

  

  return (
    <Router>
       <center>Website Under Maintenance!, Sorry For Inconvenience.</center>
      <div>
        {/* Pass the handleLogin function as a prop to the Register component */}
        {/* {
          localStorage.getItem('token')!=undefined?
          <Navbar />:
          <></>
        } */}

        <Routes>
         {
          //  localStorage.getItem('token')!=undefined?
           <>
              {/* <Route
                path="/"
                element={<Dashboard handleLogin={handleLogin} token={token} />}
              />
              <Route
                path="/Dash"
                element={<Dashboard handleLogin={handleLogin} token={token} />}
              /> */}
              {/* <Route
                path="/Results"
                element={<SemResults handleLogin={handleLogin} token={token} />}
              />
               <Route
                path="/DetailedSem"
                element={<DetailedSem handleLogin={handleLogin} token={token} />}
              />
              <Route
                path="/DetailedSubject"
                element={<DetailedSubject handleLogin={handleLogin} token={token} />}
              /> */}
          {/* </>: */}
          {/* <> */}
            {/* <Route path="*" element={<LoginPage handleLogin={handleLogin} token={token} />
            }
            /> */}
          </>
         }
          
                           
        </Routes>
      </div>
    </Router>
  );
}

export default App;
